import { addPrices, substractPrices } from './operators'

export interface PriceData {
  unitary_price: number
  unitary_cost?: number
  quantity?: number | null
  tax_ratio?: number | null
  is_second_hand?: boolean | null
  discount?: number | null
  discount_type?: string | null
}

export function calculateTotalPrice(args: PriceData) {
  const quantity = args.quantity ?? 1
  const unitPriceCents = Math.round(args.unitary_price * 100)

  const totalPriceCents = unitPriceCents * quantity

  const discount = args.discount ?? 0
  const discountType = args.discount_type

  if (discountType === 'percent') {
    const discountCents = totalPriceCents * (discount / 100)
    return Math.round(totalPriceCents - discountCents) / 100
  }
  else if (discountType === 'value') {
    const discountCents = discount * 100
    return Math.round(totalPriceCents - discountCents) / 100
  }
  else {
    return Math.round(totalPriceCents) / 100
  }
}

export function calculateTaxesPrice(args: PriceData) {
  // vat on margin
  if (args.is_second_hand) {
    const margin
      = (args.quantity ?? 1)
      * substractPrices(args.unitary_price, args.unitary_cost)
    const discount = calculateSubtotalDiscount(args)
    const base = substractPrices(margin, discount)

    return substractPrices(base, base / (1 + (args.tax_ratio ?? 0.2)))
  }

  const total = calculateTotalPrice(args)
  const netPrice = calculateSubtotalPrice(args)
  const taxRatio = args.tax_ratio ?? 0

  if (!taxRatio)
    return 0

  return substractPrices(total, netPrice)
}

export function calculateSubtotalPrice(args: PriceData) {
  const total = calculateTotalPrice(args)

  if (args.is_second_hand)
    return total

  const taxRatio = args.tax_ratio ?? 0.2
  if (!taxRatio)
    return total

  return Math.round((total / (1 + taxRatio)) * 100) / 100
}

export function calculateSubtotalDiscount(args: PriceData) {
  const totalDiscounted = calculateSubtotalPrice(args)
  const totalBase = calculateSubtotalPrice({
    ...args,
    discount: 0,
    discount_type: null,
  })

  return substractPrices(totalBase, totalDiscounted)
}
export function calculateTotalDiscount(args: PriceData) {
  const totalDiscounted = calculateTotalPrice(args)
  const totalBase = calculateTotalPrice({
    ...args,
    discount: 0,
    discount_type: null,
  })

  return substractPrices(totalBase, totalDiscounted)
}

export function calculateTotalCost(args: PriceData) {
  if (!args.unitary_cost) {
    return 0
  }

  const quantity = args.quantity ?? 1
  const unitCostCents = Math.round(args.unitary_cost * 100)

  const totalCostCents = unitCostCents * quantity

  return Math.round(totalCostCents) / 100
}

export function calculateTotalGain(args: PriceData) {
  let totalCost = calculateTotalCost(args)
  const subtotal = calculateSubtotalPrice(args)

  if (args.is_second_hand) {
    const vatOnMargin = calculateTaxesPrice(args)
    totalCost = addPrices(totalCost, vatOnMargin)
  }

  const totalBaseGain = substractPrices(subtotal, totalCost)
  const totalDiscount = calculateSubtotalDiscount(args)

  return substractPrices(totalBaseGain, totalDiscount)
}
